<button
  *ngIf="action().isHidden == null || (!action().isHidden())"
  (click)="callActionFunction.emit(action().controlAction)"
  [color]="action().color | valueOrFunction"
  [disabled]="action().isDisabled != undefined && action().isDisabled()"
  [matTooltip]="action().tooltip"
  [matTooltipShowDelay]="action().tooltipDelay || 0"
  mat-raised-button
>
  <span
    style="display: flex; align-items: center; justify-content: center; gap: 0.5rem"
    [class]="(action().isDisabled != undefined && action().isDisabled()) ? 'mat-button-disabled' : ''">
    @if (action().icon | valueOrFunction) {
      @if (action().displayName | valueOrFunction) {
        {{action().displayName | valueOrFunction}}
      }
      <mat-icon
        style="display: flex;"
        class="centered"
        *ngIf="action().icon | valueOrFunction"
        [svgIcon]="action().icon | valueOrFunction"
      />
    } @else {
      {{ action().displayName | valueOrFunction }}
    }
 </span>
</button>
