import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {BaseFormElementComponent} from '../base-form-element.class';
import {NgbDateAdapter, NgbDateParserFormatter, NgbInputDatepicker} from '@ng-bootstrap/ng-bootstrap';
import {DateValidator, EsvgFiles} from 'frontier/nucleus';
import {IDateFormElement} from '../form-data.interface';
import {
  GermanDatepickerAdapter,
  GermanDatepickerFormatter
} from 'frontier/browserkit/src/lib/components/bootstrap-datepicker-adapter/german-datepicker-adapter.service';
import {AbstractControl, FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Subscription} from 'rxjs';
import {MatIcon} from '@angular/material/icon';
import {MatIconButton} from '@angular/material/button';
import {RemoveDatepickerValidationDirective} from '../../../../../../directives/remove-datepicker-validation.directive';
import {MatInput} from '@angular/material/input';
import {NgFor, NgIf} from '@angular/common';
import {MatError, MatFormField, MatLabel, MatSuffix} from '@angular/material/form-field';
import {DateTime} from 'luxon';

@Component({
  selector: 'kpi4me-date-element',
  templateUrl: './date-element.component.html',
  styleUrls: ['./date-element.component.scss', '../form-element.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  providers: [
    {provide: NgbDateAdapter, useClass: GermanDatepickerAdapter},
    {provide: NgbDateParserFormatter, useClass: GermanDatepickerFormatter},
  ],
  standalone: true,
  imports: [
    MatFormField,
    NgIf,
    MatLabel,
    MatInput,
    NgbInputDatepicker,
    FormsModule,
    RemoveDatepickerValidationDirective,
    ReactiveFormsModule,
    MatIconButton,
    MatSuffix,
    MatIcon,
    MatError,
    NgFor,
  ],
})
export class DateElementComponent extends BaseFormElementComponent implements OnDestroy {
  @ViewChild(NgbInputDatepicker) datePickerRef: NgbInputDatepicker;
  @ViewChild('inputRef', {static: true}) inputRef: ElementRef;

  private insertDots: boolean;
  private subs = new Subscription();
  protected readonly EsvgFiles = EsvgFiles;

  private _initialValue: string;

  override _formControlElement: AbstractControl;
  @Input()
  override set formControlElement(c: AbstractControl) {
    if (c) {
      this._initialValue = DateTime.fromISO(c.value).toFormat('yyyy-MM-dd');
      this._formControlElement = c;
      this.cdr.detectChanges();
    }
  };

  override get formControlElement(): FormControl {
    return this._formControlElement as FormControl;
  }

  constructor(private cdr: ChangeDetectorRef,
              private renderer: Renderer2) {
    super();
  }

  @Input()
  override set data(form: IDateFormElement) {
    this.insertDots = form.insertDelimiter;

    this.getFormControl().addValidators(DateValidator.isoFormat)
    super.data = form;

    this.getFormControl().updateValueAndValidity();
  };

  @Output() selectionChange = new EventEmitter();

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onKeyDown(evt: KeyboardEvent, d: NgbInputDatepicker) {
    if (this.insertDots !== true) {
      return;
    }
    if (evt.key === 'Backspace') {
      return;
    }
    evt.preventDefault();
    const onlyDigits = evt.key.match(/^[0-9]*$/);
    if (!onlyDigits) {
      return;
    }
    const formValue = this.inputRef.nativeElement.value;
    if (formValue.length >= 10) {
      return;
    }
    this.cdr.detach();
    let outputValue;
    if (formValue != null) {
      const valueWithoutDots = formValue.replace(/\./g, '');
      outputValue = valueWithoutDots + evt.key;
    } else {
      outputValue = '' + evt.key;
    }
    let outputValueWithDots = '';
    for (let i = 0; i < outputValue.length; i++) {
      if (i == 1 || i == 3) {
        outputValueWithDots += outputValue[i] + '.';
      } else {
        outputValueWithDots += outputValue[i];
      }
    }

    // this.formControlElement.patchValue(outputValueWithDots, {emitEvent: true});
    this.renderer.setProperty(this.inputRef.nativeElement, 'value', outputValueWithDots);
    this.inputRef.nativeElement.dispatchEvent(new Event('change'), {bubbles: true});
    this.formControlElement.updateValueAndValidity();

    this.cdr.reattach();

    this.cdr.detectChanges();
    console.log(outputValue, evt);

  }

  getErrorMessage(): string[] {
    const formControl = this.getFormControl();
    const errorMessage: string[] = [];
    if (formControl.hasError('required')) {
      errorMessage.push('Bitte geben Sie ein Datum an.');
    }
    if (formControl.hasError('noIsoFormat')) {
      errorMessage.push('Der eingegebene Wert ist kein Gültiges Datum im Format TT.MM.JJJJ.')
    }

    return errorMessage;
  }

  override onFocusOut(evt: FocusEvent) {
    return;
  }

  dateChange() {
    if (
      DateTime.fromISO(this._formControlElement.value).toFormat('yyyy-MM-dd') === this._initialValue
    ) {
      return;
    }
    this.modelChange.emit(this.formControlElement);
  }
}
